import React from "react";
//animation variants
import {
  TransitionVariants,
  DescVariants,
  TransitionElemVars,
} from "../animations/PageTransitions";

//useful modules
import { motion, AnimatePresence } from "framer-motion";
//component styles
import {
  StyledHome,
  StyledSlideOne,
  StyledSlideTwo,
  StyledSlideThree,
  TransitionElem,
} from "../components/styles/StyledComps";

//componenets/utils
import SliderWatcher from "../util/SliderWatcher";
import SkillSection from "../components/SkillSection";
import Portfolio from "../components/Portfolio";
import { scrollTo } from "../util/ScrollTop";
//images
import clean from "../images/clean.png";
import performant from "../images/performant.png";
import scalable from "../images/scalable.png";
import maintenance from "../images/maintain.png";
//fonts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

const spanVariant = {
  hidden: {
    opacity: 0,
    x: -100,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.5 },
  }),
};

const Home = () => {
  //slider ref and initialization
  const sliderRef = SliderWatcher();

  return (
    <>
      <StyledHome
        className="infos"
        variants={TransitionVariants}
        animate="visible"
        initial="initial"
        exit="exit"
      >
        <div className="infoleft">
          <motion.h1>
            <motion.span
              style={{ display: "inline-block" }}
              variants={spanVariant}
              custom={1}
              initial="hidden"
              animate="visible"
              className="first-line"
            >
              Hello. I'm <span className="accented">Davit</span>.
            </motion.span>
            <br />
            <motion.span
              style={{ display: "inline-block" }}
              variants={spanVariant}
              custom={2}
              initial="hidden"
              animate="visible"
              className="second-line"
            >
              I create <span className="accented"> awesome </span>
            </motion.span>
            <motion.span
              style={{ display: "inline-block" }}
              variants={spanVariant}
              custom={3}
              initial="hidden"
              animate="visible"
              className="third-line"
            >
              web experiences.
            </motion.span>
          </motion.h1>
        </div>
        <AnimatePresence>
          <motion.div className="inforight">
            <StyledSlideOne
              variants={DescVariants}
              initial="initial"
              animate="animate"
              className={`skilldesc ${0}`}
              custom={0}
            >
              <h2>
                My <span>{"<Code/>"} </span> Is
              </h2>
              <div className="descbox">
                <div className="descriptor maintain">
                 
                  <div className="img">
                    <img src={maintenance} alt="maintenance" />
                  </div>
                  <h3>Maintainable</h3>
                </div>
                <div className="descriptor optimized">
                
                  <div className="img">
                    <img  src={performant} alt="performant" />
                
                  </div>
                  <h3>Optimized</h3>
                </div>
                <div className="descriptor scalable">
                 
                  <div className="img">
                    <img src={scalable} alt="scalable" />
                  </div>
                  <h3>Scalable</h3>
                </div>
                <div className="descriptor clean">
                  
                  <div className="img">
                    <img src={clean} alt="clean" />
                  </div>
                  <h3>clean</h3>
                </div>
              </div>
            </StyledSlideOne>

            <StyledSlideTwo
              variants={DescVariants}
              initial="initial"
              animate="animate"
              className={`skilldesc ${1}`}
              custom={1}
            >
              <p>
                I'm always <span>learning</span> and <span>improving</span>.
                Take a look at my side projects.
                <button
                  onClick={(e) => {
                    scrollTo("portfolio");
                  }}
                >
                  Portfolio
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    style={{
                      fontSize: "2rem",
                      padding: 0,
                      marginLeft: "1rem",
                    }}
                  />
                </button>
              </p>
            </StyledSlideTwo>
            <StyledSlideThree
              variants={DescVariants}
              initial="initial"
              animate="animate"
              className={`skilldesc ${2}`}
              custom={2}
            >
              <h2>What I'm up to</h2>
              <p>
                I'm working full time and tending to my side projects. Reach out
                to me at
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/in/davit-darsavelidze-b36505210/"
                >
                  LinkedIn
                </a>
                or
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://github.com/daveDarsa"
                >
                  Github
                </a>
                , or send me an email from the form down below.
              </p>
              <button
                onClick={(e) => {
                  scrollTo("contact");
                }}
              >
                Email me
                <br />
                <FontAwesomeIcon
                  icon={faArrowDown}
                  style={{
                    fontSize: "2rem",
                    padding: 0,
                    marginLeft: "1rem",
                  }}
                />
              </button>
            </StyledSlideThree>
          </motion.div>
        </AnimatePresence>
        <TransitionElem
          ref={sliderRef}
          variants={TransitionElemVars}
          animate="animate"
          initial="initial"
          // onUpdate={onUpdate}
        />
      </StyledHome>
      <SkillSection />
      <Portfolio />
    </>
  );
};

export default Home;
