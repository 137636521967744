import React from "react";
import { NavVariants } from "../animations/PageTransitions";
import ghicon from "../images/ghLight.png";
import linkedin from "../images/linkedin.svg";

//styled components
import { StyledLink, StyledNav, Styledul } from "./styles/StyledComps";
import { scrollTo } from "../util/ScrollTop";
const Nav = () => {
  const scrollToSection = (e, location) => {
    e.preventDefault();
    e.stopPropagation();
    scrollTo(location);
  };
  return (
    <StyledNav variants={NavVariants} initial="hidden" animate="visible">
      <div className="logoBox logobox1">
        <StyledLink to="/" aria-label="Home">
          <svg
            viewBox="0 0 9 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.376 6.552C8.376 7.416 8.124 8.06 7.62 8.484C7.124 8.908 6.352 9.12 5.304 9.12H1.26C1.068 9.12 0.928 9.076 0.84 8.988C0.752 8.9 0.708 8.76 0.708 8.568V0.792C0.708 0.6 0.752 0.46 0.84 0.372C0.928 0.284 1.068 0.24 1.26 0.24H5.304C6.352 0.24 7.124 0.452 7.62 0.875999C8.124 1.3 8.376 1.944 8.376 2.808V6.552ZM5.304 9C6.304 9 7.044 8.8 7.524 8.4C8.012 8 8.256 7.384 8.256 6.552V2.808C8.256 1.976 8.012 1.36 7.524 0.96C7.044 0.56 6.304 0.36 5.304 0.36H1.26C1.108 0.36 0.996 0.396 0.924 0.468C0.86 0.532 0.828 0.64 0.828 0.792V8.568C0.828 8.72 0.86 8.832 0.924 8.904C0.996 8.968 1.108 9 1.26 9H5.304ZM2.136 7.92V1.44H5.04C5.448 1.44 5.776 1.484 6.024 1.572C6.28 1.66 6.476 1.78 6.612 1.932C6.748 2.076 6.84 2.252 6.888 2.46C6.936 2.66 6.96 2.876 6.96 3.108V6.288C6.96 6.52 6.936 6.736 6.888 6.936C6.848 7.136 6.76 7.308 6.624 7.452C6.488 7.596 6.292 7.712 6.036 7.8C5.78 7.88 5.448 7.92 5.04 7.92H2.136ZM5.04 1.56H2.256V7.8H5.04C5.424 7.8 5.732 7.764 5.964 7.692C6.204 7.612 6.388 7.508 6.516 7.38C6.644 7.244 6.728 7.084 6.768 6.9C6.816 6.708 6.84 6.504 6.84 6.288V3.108C6.84 2.892 6.816 2.688 6.768 2.496C6.72 2.304 6.632 2.14 6.504 2.004C6.376 1.868 6.192 1.76 5.952 1.68C5.72 1.6 5.416 1.56 5.04 1.56ZM3.552 2.652H4.98C5.172 2.652 5.312 2.7 5.4 2.796C5.496 2.884 5.544 3.028 5.544 3.228V6.144C5.544 6.344 5.496 6.492 5.4 6.588C5.312 6.684 5.172 6.732 4.98 6.732H3.552V2.652ZM4.98 2.772H3.672V6.612H4.98C5.132 6.612 5.244 6.576 5.316 6.504C5.388 6.424 5.424 6.304 5.424 6.144V3.228C5.424 3.076 5.388 2.964 5.316 2.892C5.244 2.812 5.132 2.772 4.98 2.772Z"
              fill="white"
            />
          </svg>
        </StyledLink>
      </div>
      <div className="menuItems">
        <Styledul>
          <li>
            <button onClick={(e) => scrollToSection(e, "about")}>About</button>
          </li>
          <li>
            <button onClick={(e) => scrollToSection(e, "portfolio")}>
              Portfolio
            </button>
          </li>
          <li>
            <button onClick={(e) => scrollToSection(e, "contact")}>
              Contact
            </button>
          </li>
          <li className="navimage">
            <a
              href="https://github.com/daveDarsa"
              rel="noreferrer"
              target="_blank"
            >
              <img src={ghicon} alt="github icon" width="20px" height="20px" />
            </a>
          </li>
          <li className="navimage">
            <a
              href="https://www.linkedin.com/in/davit-darsavelidze-b36505210/"
              rel="noreferrer"
              target="_blank"
              className="linkedin"
            >
              <img
                src={linkedin}
                alt="LinkedIn icon"
                width="100%"
                height="100%"
              />
            </a>
          </li>
        </Styledul>
      </div>
    </StyledNav>
  );
};

export default Nav;
